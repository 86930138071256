import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Container } from 'semantic-ui-react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import config from './config';
import Home from './Home';
import Login from './Login';

const HasAccessToRouter = () => {
  const history = useHistory(); // example from react-router

  const customAuthHandler = () => {
    //Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  return (
    <Security {...config.oidc} onAuthRequired={customAuthHandler}>
      {/* <Navbar /> */}
      <Container style={{ marginTop: '7em', width: '1000' }}>
        <Route path='/' exact component={Home} />
        <Route path='/implicit/callback' component={LoginCallback} />
        <Route path='/login' component={Login} />
        <SecureRoute path='/features' component={Home} />
        <SecureRoute path='/subscriptions' component={Home} />
        <SecureRoute path='/mappings' component={Home} />
        <SecureRoute path='/permissions' component={Home} />
        <SecureRoute path='/addons' component={Home} />
      </Container>
    </Security>
  );
};

let graphqlClient = new ApolloClient({
  uri: config.gql,
  request: async (r) => {
    r.setContext(() => {
      // not using okta's token manager because it's async and even awaiting a response to get the token
      // sends the request out without adding the header
      let oktaStorage = localStorage.getItem('okta-token-storage');
      oktaStorage = JSON.parse(oktaStorage);

      // if we don't have an accessToken, then don't send the request
      if (
        !oktaStorage ||
        !oktaStorage.accessToken ||
        !oktaStorage.accessToken.accessToken
      )
        return;

      return {
        headers: {
          authorization: 'Bearer ' + oktaStorage.accessToken.accessToken,
          appcontext: 'accesscontrol',
        },
      };
    });
  },
});

const App = () => (
  <ApolloProvider client={graphqlClient}>
    <div>
      <Router>
        <HasAccessToRouter />
      </Router>
    </div>
  </ApolloProvider>
);

export default App;
