import { gql } from 'apollo-boost';

export const queries = {
  GET_ENTITIES: gql`
    {
      getAccessControlEntities {
        application {
          appid
          name
        }
        subscriptions {
          subscriptionId
          name
          marketingUrl
          entryPoints {
            name
            viewType
            objectTypes
          }
          icon
          releaseStage
        }
        features {
          featureId
          name
          description
        }
        permissions {
          permissionId
          name
          description
        }
        addons {
          addonId
          name
          description
          quota
          subscriptions
          permissions
          uoms
          addonType
          features
        }
      }
    }
  `,
  GET_AC_MAPPINGS: gql`
    {
      getAccessControlMapping {
        application
        subscriptionControl {
          subscription
          subscriptionName
          featureControl {
            feature {
              featureId
              name
            }
            quota
            allow3rdParty
            permissions {
              permissionId
              name
              description
            }
          }
        }
      }
    }
  `,
  REMOVE_MAPPING: gql`
    mutation removeAccessControlMapping($input: AccessControlMappingInput) {
      removeAccessControlMapping(input: $input)
    }
  `,
  ADD_MAPPING: gql`
    mutation addAccessControlMapping($input: AccessControlMappingInput) {
      addAccessControlMapping(input: $input)
    }
  `,
  UPDATE_MAPPING: gql`
    mutation updateAccessControlMapping($input: AccessControlMappingInput) {
      updateAccessControlMapping(input: $input)
    }
  `,
  GET_OBJECT_TYPES: gql`
    query objectTypes {
      objectBaseTypes
    }
  `,
};

export const ADD_ENTITY = gql`
  mutation addAccessControlEntity($input: AccessControlEntityInput) {
    addAccessControlEntity(input: $input)
  }
`;

export const DELETE_ENTITY = gql`
  mutation deleteAccessControlEntity($input: AccessControlEntityInput) {
    deleteAccessControlEntity(input: $input)
  }
`;

export const UPDATE_ENTITY = gql`
  mutation updateAccessControlEntity($input: AccessControlEntityInput) {
    updateAccessControlEntity(input: $input)
  }
`;

export const GET_ACCOUNTS_FOR_SUB = gql`
  query accountsForSub($subscriptionId: String!) {
    getAccountsForSubscription(subscriptionId: $subscriptionId) {
      name
    }
  }
`;
