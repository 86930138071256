import React from 'react';

function Welcome(props) {
    return <div>
        <h1>
            Oceansmart Access Control
              </h1>
        <p>
            Oceansmart access control allows application admins to centrally define access level for various oceansmart subscriptions. Access level is defined via permissions assigned to features.
              </p>
        <ul>
            <li>
                Select "permissions" to add/remove permissions to the app.
                </li>
            <li>
                Select "features" to add/remove features to the app.
                </li>
            <li>
                Select "subscriptions" to add/remove subscriptions to the app
                </li>
            <li>
                The "MAPPINGS" page allows you to map applicable list of permissions to features for each subscription.
                </li>
        </ul>
    </div>;
}

export default Welcome;