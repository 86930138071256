import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

import SmartGrid from 'material-table';

import { queries } from './gql';
import MappingModal from './MappingModal';

import { tableIcons } from './icons';

function Mappings(props) {
  const { data: entities } = useQuery(queries.GET_ENTITIES, {
    fetchPolicy: 'no-cache',
  });

  const { data, loading, refetch } = useQuery(queries.GET_AC_MAPPINGS, {
    fetchPolicy: 'no-cache',
  });

  const [removeAccessControlMapping] = useMutation(queries.REMOVE_MAPPING, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [addAccessControlMapping] = useMutation(queries.ADD_MAPPING, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateAccessControlMapping] = useMutation(queries.UPDATE_MAPPING, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  async function handleSave(mapping) {
    let input = {
      appid: entities.getAccessControlEntities.application.appid,
      permissions: mapping.permissions.map((m) => m.permissionId),
      subscriptionId: mapping.subscriptionId.subscriptionId,
      featureId: mapping.featureId.featureId,
      allow3rdParty: mapping.allow3rdParty,
    };

    if (isEditing) {
      updateAccessControlMapping({
        variables: {
          input: input,
        },
      });
    } else {
      addAccessControlMapping({
        variables: {
          input: input,
        },
      });
    }

    closeDialog();
  }

  function getGridData() {
    if (!data || !data.getAccessControlMapping) return [];

    let result = data.getAccessControlMapping.subscriptionControl.map((s) => {
      return s.featureControl.map((f) => {
        return {
          subscriptionId: s.subscription,
          subscriptionName: s.subscriptionName,
          featureId: f.feature.featureId,
          featurename: f.feature.name,
          allow3rdParty: f.allow3rdParty ? 'Yes' : 'No',
          permissions: f.permissions.map((p) => p.permissionId).join(' | '),
        };
      });
    });

    return [].concat.apply([], result);
  }

  const [isEditing, setisEditing] = useState(false);
  const [edit, setEdit] = useState(null);
  function onEditClick(event, rowData) {
    setisEditing(true);

    // subscriptionId: "POSITION"
    // subscriptionName: "SMART Position"
    // featureId: "PW-CONTACTS"
    // featurename: "POI Widget - Contacts"
    // allow3rdParty: "No"
    // permissions: "NA | FA | RO"

    let e = entities.getAccessControlEntities;

    let mapping = {
      subscriptionId: {
        subscriptionId: rowData.subscriptionId,
        name: e.subscriptions.find(
          (p) => p.subscriptionId === rowData.subscriptionId
        ).name,
      },
      featureId: {
        featureId: rowData.featureId,
        name: e.features.find((p) => p.featureId === rowData.featureId).name,
      },
      allow3rdParty: rowData.allow3rdParty === 'Yes',
      permissions: rowData.permissions.split(' | ').map((pid) => {
        return {
          permissionId: pid,
          name: e.permissions.find((p) => p.permissionId === pid).name,
        };
      }),
    };

    setEdit(mapping);

    openDialog();
  }

  async function onDeleteClick(event, rowData) {
    if (window.confirm('Are you sure?')) {
      removeAccessControlMapping({
        variables: {
          input: {
            appid: data.getAccessControlMapping.application,
            subscriptionId: rowData.subscriptionId,
            featureId: rowData.featureId,
          },
        },
      });
    }
  }

  const colDef = [
    {
      title: 'Subscription',
      field: 'subscriptionName',
      type: 'string',
      defaultGroupOrder: 0,
    },
    { title: 'Feature', field: 'featurename', type: 'string' },
    { title: 'Permissions', field: 'permissions', type: 'string' },
    { title: 'Allow 3rd Party', field: 'allow3rdParty', type: 'string' },
  ];

  const [open, setOpen] = React.useState(false);
  const closeDialog = () => {
    setOpen(false);
    setisEditing(false);
    setEdit(null);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={1} direction='row'>
        <Grid item xs={12}>
          <h2>mappings</h2>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Paper>
              <SmartGrid
                columns={colDef}
                data={getGridData()}
                title={''}
                icons={tableIcons}
                options={{
                  grouping: true,
                  filtering: true,
                }}
                sorting={true}
                search={true}
                actions={[
                  (rowData) => ({
                    icon: EditIcon,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => onEditClick(event, rowData),
                  }),
                  (rowData) => ({
                    icon: DeleteIcon,
                    tooltip: 'Delete',
                    onClick: (event, rowData) => onDeleteClick(event, rowData),
                  }),
                ]}
              />
            </Paper>
          )}
        </Grid>
      </Grid>

      <br></br>
      <Button variant='contained' color='primary' onClick={openDialog}>
        <b>Add NEW</b>
      </Button>
      {open && (
        <MappingModal
          closeDialog={closeDialog}
          handleSave={handleSave}
          edit={edit}
          entities={entities}
        />
      )}
    </div>
  );
}

export default Mappings;
