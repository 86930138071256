import React, { useState } from 'react';

import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EntryPoints from './EntryPoints/EntryPoints';
import IconPicker from './IconPicker/IconPicker';
import releaseStages from './constants/releaseStages';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_ACCOUNTS_FOR_SUB } from './gql';

function EntitiesModal(props) {
  const { type, entities, edit, closeDialog } = props;
  const gqlClient = useApolloClient();

  const [entity, setEntity] = useState(edit || getEmptyEntity());

  function getEmptyEntity() {
    let empty = {
      id: '',
      name: '',
      description: '',
    };
    if (type === 'subscriptions') {
      empty.marketingUrl = '';
      empty.entryPoints = [];
      empty.icon = '';
      empty.releaseStage = 'Draft';
    }
    return type === 'addons'
      ? {
          ...empty,
          quota: false,
          uoms: '',
          subscriptions: [],
          features: [],
          permissions: [],
          addonType: '',
        }
      : empty;
  }

  function handleInput(param) {
    setEntity({
      ...entity,
      [param.fieldName]: param.value,
    });
  }

  function handleQuota() {
    setEntity({
      ...entity,
      quota: !entity.quota,
    });
  }

  function handlePermission(event, value) {
    setEntity({
      ...entity,
      permissions: value,
    });
  }

  function handleSubscription(event, value) {
    setEntity({
      ...entity,
      subscriptions: value,
    });
  }

  function handleFeature(event, value) {
    setEntity({
      ...entity,
      features: value,
    });
  }

  function isIdTaken(input) {
    try {
      let idFieldName = input.type.substring(0, input.type.length - 1) + 'Id';

      let entityList = entities[input.type].map((p) =>
        p[idFieldName].toUpperCase()
      );

      return entityList.includes(input.id.toUpperCase());
    } catch (e) {
      return false;
    }
  }

  function validate(entity) {
    if (!entity.id || !entity.name) {
      alert('ID and Name are required');
      return false;
    }

    if (!entity.id.match(/^[0-9a-zA-Z_-]+$/)) {
      alert('ID should be alphanumeric, dash or underscore only. No spaces');
      return false;
    }

    if (entity.id.length > 20) {
      alert('ID max chars (20) exceeded.');
      return false;
    }

    if (entity.name.length > 50) {
      alert('Name max chars (50) exceeded.');
      return false;
    }

    if (type === 'subscriptions') {
      if (
        entity.marketingUrl !== '' &&
        !entity?.marketingUrl?.match(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/gim
        )
      ) {
        alert(
          'Must provide a valid URL for marketing URL. ex: https://oceansmart.com'
        );
        return false;
      }
      for (let i = 0; i < entity.entryPoints.length; i++) {
        const ep = entity.entryPoints[i];

        if (!ep.name) {
          alert('All entry points must have a name field.');
          return false;
        }
        if (!ep.viewType) {
          alert('All entry points must have a view type');
          return false;
        }
      }
    }

    if (type === 'addons') {
      if (entity.uoms.length > 100) {
        alert('Uoms max chars (100) exceeded.');
        return false;
      }

      if (entity.addonType.length > 20) {
        alert('Addon type max chars (20) exceeded.');
        return false;
      }
    }

    return true;
  }

  async function handleSave() {
    if (!validate(entity)) return;

    let input = {
      appid: entities.application.appid,
      type: type,
      id: entity.id,
      name: entity.name,
      description: entity.description,
    };

    if (type === 'subscriptions') {
      input.marketingUrl = entity.marketingUrl;
      input.entryPoints = entity.entryPoints.map((ep) => {
        delete ep.__typename;
        return ep;
      });
      input.icon = entity.icon;
      input.releaseStage = entity.releaseStage;
    }
    if (type === 'addons') {
      input = {
        ...input,
        permissions: entity.permissions.map((m) => m.permissionId),
        subscriptions: entity.subscriptions.map((s) => s.subscriptionId),
        uoms: entity.uoms,
        addonType: entity.addonType,
        quota: entity.quota,
        features: entity.features.map((e) => e.featureId),
      };
    }

    if (!edit && isIdTaken(input)) {
      alert('ID is already taken');
      return;
    }

    props.handleSave(input);
  }

  async function validateRelease(stage) {
    try {
      // All other stages (draft, deactivated, preview) should check for users before taking action.
      if (stage !== releaseStages.Published) {
        const { data } = await gqlClient.query({
          query: GET_ACCOUNTS_FOR_SUB,
          variables: { subscriptionId: entity.subscriptionId },
        });

        if (data.getAccountsForSubscription.length) {
          const accounts = data.getAccountsForSubscription.map(
            (acc) => acc.name
          );

          accounts[accounts.length - 1] = `and ${
            accounts[accounts.length - 1]
          }`;
          const alertStr = `The accounts ${accounts.join(
            ', '
          )} have subscriptions for this stage. You only use ${
            releaseStages.Published
          } until they are removed.`;
          alert(alertStr);
          return;
        }
      }

      handleInput({
        value: stage,
        fieldName: 'releaseStage',
      });
    } catch (error) {
      console.error(error);
      alert('There was an error validating the release stage.');
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit ' : 'Add new '} {type.substring(0, type.length - 1)}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Provide values for the fields indicated below and hit submit.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="ID"
            fullWidth
            disabled={!!edit}
            value={entity.id}
            onChange={(e) =>
              handleInput({ value: e.target.value, fieldName: 'id' })
            }
          />
          <TextField
            margin="dense"
            label="Name"
            value={entity.name}
            fullWidth
            onChange={(e) =>
              handleInput({ value: e.target.value, fieldName: 'name' })
            }
          />
          {type === 'subscriptions' && (
            <>
              <TextField
                fullWidth
                label="Marketing URL"
                margin="dense"
                onChange={(e) =>
                  handleInput({
                    value: e.target.value,
                    fieldName: 'marketingUrl',
                  })
                }
                value={entity.marketingUrl}
              />

              <InputLabel style={{ marginTop: '5px' }} shrink>
                Release Stage
              </InputLabel>
              <Select
                fullWidth
                label="View Type"
                margin="dense"
                onChange={(e) => validateRelease(e.target.value)}
                value={entity.releaseStage}
              >
                {Object.values(releaseStages).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <EntryPoints
                entryPoints={entity.entryPoints}
                onChange={handleInput}
              />
            </>
          )}
          {type === 'subscriptions' && (
            <IconPicker
              defaultValue={entity.icon}
              onChange={(e) =>
                handleInput({ value: e.selectedIcon, fieldName: 'icon' })
              }
            />
          )}
          {type !== 'subscriptions' && (
            <TextField
              margin="dense"
              value={entity.description}
              label="Description"
              fullWidth
              onChange={(e) =>
                handleInput({ value: e.target.value, fieldName: 'description' })
              }
            />
          )}
          {type === 'addons' && (
            <Grid container spacing={0} direction="row">
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ paddingTop: '20px' }}
                  control={
                    <Switch
                      checked={entity.quota}
                      onChange={handleQuota}
                      color="primary"
                    />
                  }
                  label="Apply Quota"
                />
              </Grid>
              <Grid item xs={12}>
                {entity.quota && (
                  <TextField
                    margin="dense"
                    value={entity.uoms}
                    label="Units of measurement (comma separated)"
                    // multiline
                    // rows={4}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      handleInput({ value: e.target.value, fieldName: 'uoms' })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={entity.permissions}
                  onChange={(event, value) => handlePermission(event, value)}
                  options={entities.permissions}
                  getOptionSelected={(option, value) => {
                    return option.permissionId === value.permissionId;
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Permissions"
                      placeholder="Permissions"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={entity.subscriptions}
                  onChange={(event, value) => handleSubscription(event, value)}
                  options={entities.subscriptions}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return option.subscriptionId === value.subscriptionId;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Subscriptions"
                      placeholder="Subscriptions"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={entity.features}
                  onChange={(event, value) => handleFeature(event, value)}
                  options={entities.features}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return option.featureId === value.featureId;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Features"
                      placeholder="Features"
                    />
                  )}
                />
              </Grid>
              <TextField
                margin="dense"
                label="Addon Type. ex: 'folder'"
                value={entity.addonType}
                fullWidth
                onChange={(e) =>
                  handleInput({ value: e.target.value, fieldName: 'addonType' })
                }
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EntitiesModal;
