import { useApolloClient } from '@apollo/react-hooks';
import { queries } from '../gql';
import { useEffect, useState } from 'react';

// The most simple cache in the world.
let objectTypes;

const interval = setInterval(() => {
  objectTypes = null;
}, 3600000); // clear object types every hour the user is on the page in case they change.

window.addEventListener('unload', () => {
  clearInterval(interval);
});

function useObjectTypes() {
  const client = useApolloClient();
  const [needsFetch, setNeedsFetch] = useState(!objectTypes);

  useEffect(() => {
    const checkCacheExists = setInterval(() => {
      if (!objectTypes) setNeedsFetch(true);
    }, 1000);
    return () => {
      clearInterval(checkCacheExists);
    };
  }, []);

  useEffect(() => {
    if (needsFetch) {
      client
        .query({
          query: queries.GET_OBJECT_TYPES,
        })
        .then((res) => {
          objectTypes = res.data.objectBaseTypes;
          setNeedsFetch(false);
        })
        .catch(console.log);
    }
  }, [client, needsFetch]);

  return objectTypes || [];
}

export default useObjectTypes;
