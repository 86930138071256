// before commit: uncomment these

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const REDIRECT = process.env.REACT_APP_OKTA_REDIRECT_URI;
const GQL_URL = process.env.REACT_APP_GRAPHQL_SERVER;

//dev
// const CLIENT_ID = '0oaqt29rjnjmjse5S0h7';
// const ISSUER = 'https://oceaneering.oktapreview.com/oauth2/default';
// const REDIRECT = 'http://localhost:3000/implicit/callback';
// const GQL_URL = 'http://localhost:5000/graphql';

//prod
// const CLIENT_ID = '0oa72dchd2hRbfbYD2p7'
// const ISSUER = 'https://oceaneering.okta.com/oauth2/aus60s09n0Bz1fSrA2p7'
// const REDIRECT = 'http://localhost:3000/implicit/callback'
// const GQL_URL = 'http://localhost:5000/graphql'

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  },
  gql: GQL_URL,
};
