import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import SmartIcon from './IconPicker/SmartIcon/SmartIcon';

import SmartGrid from 'material-table';

import { queries, DELETE_ENTITY, ADD_ENTITY, UPDATE_ENTITY } from './gql';
import { tableIcons } from './icons';

import EntitiesModal from './EntitiesModal';
import EntryPoint from './EntryPoints/EntryPoint';

function Entities(props) {
  const { type } = props;

  const { data, loading, refetch } = useQuery(queries.GET_ENTITIES, {
    fetchPolicy: 'no-cache',
  });

  const { data: mapping } = useQuery(queries.GET_AC_MAPPINGS, {
    fetchPolicy: 'no-cache',
  });

  const [deleteAccessControlEntity] = useMutation(DELETE_ENTITY, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [addAccessControlEntity] = useMutation(ADD_ENTITY, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateAccessControlEntity] = useMutation(UPDATE_ENTITY, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  function getGridData() {
    let result =
      (data &&
        data.getAccessControlEntities &&
        data.getAccessControlEntities[type]) ||
      [];

    return result;
  }

  const [isEditing, setisEditing] = useState(false);
  const [edit, setEdit] = useState(null);
  function onEditClick(event, rowData) {
    setisEditing(true);

    let e = data.getAccessControlEntities;

    let addons =
      type === 'addons'
        ? {
            subscriptions: rowData.subscriptions.map((sid) => {
              return {
                subscriptionId: sid,
                name: e.subscriptions.find((s) => s.subscriptionId === sid)
                  .name,
              };
            }),
            permissions: rowData.permissions.map((pid) => {
              return {
                permissionId: pid,
                name: e.permissions.find((p) => p.permissionId === pid).name,
              };
            }),
            features: rowData.features.map((fid) => {
              return {
                featureId: fid,
                name: e.features.find((p) => p.featureId === fid).name,
              };
            }),
          }
        : {};

    setEdit({
      id: rowData[type.substring(0, type.length - 1) + 'Id'],
      ...rowData,
      ...addons,
    });

    openDialog();
  }

  function onDeleteClick(event, rowData) {
    let mapped = false;

    let entityId = rowData[type.substring(0, type.length - 1) + 'Id'];

    if (type === 'features') {
      try {
        mapping.getAccessControlMapping.subscriptionControl.forEach((sc) => {
          if (
            sc.featureControl
              .map((fc) => fc.feature.featureId)
              .includes(entityId.toUpperCase())
          ) {
            alert(
              `There's a mapping in ${sc.subscription} using this feature, please remove the mapping first.`
            );
            mapped = true;
          }
        });

        if (
          data.getAccessControlEntities.addons
            .map((a) => a.features)
            .flat()
            .includes(entityId)
        ) {
          alert(
            "There's an addon using this feature, please update the addon first."
          );
          mapped = true;
        }
      } catch (e) {
        console.log(e);
      }
    } else if (type === 'permissions') {
      try {
        mapping.getAccessControlMapping.subscriptionControl.forEach((sc) => {
          let allPerms = sc.featureControl
            .map((fc) => fc.permissions.map((p) => p.permissionId))
            .flat();

          if (allPerms.includes(entityId)) {
            alert(
              `There are mappings using this feature, please remove them first.`
            );
            mapped = true;
          }

          if (
            data.getAccessControlEntities.addons
              .map((a) => a.permissions)
              .flat()
              .includes(entityId)
          ) {
            alert(
              "There's an addon using this permission, please update the addon first."
            );
            mapped = true;
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (!mapped && window.confirm('Are you sure?')) {
      deleteAccessControlEntity({
        variables: {
          input: {
            appid: data.getAccessControlEntities.application.appid,
            type: type,
            id: entityId,
          },
        },
      });
    }
  }

  async function handleSave(input) {
    if (isEditing) {
      await updateAccessControlEntity({
        variables: {
          input: input,
        },
      });
    } else {
      await addAccessControlEntity({
        variables: {
          input: input,
        },
      });
    }

    closeDialog();
  }

  const permissionColumns = [
    { title: 'Permission Id', field: 'permissionId', type: 'string' },
    { title: 'Name', field: 'name', type: 'string' },
    { title: 'Description', field: 'description', type: 'string' },
  ];

  const featureColumns = [
    { title: 'Feature Id', field: 'featureId', type: 'string' },
    { title: 'Name', field: 'name', type: 'string' },
    { title: 'Description', field: 'description', type: 'string' },
  ];

  const subscriptionColumns = [
    { title: 'Subscription Id', field: 'subscriptionId', type: 'string' },
    { title: 'Name', field: 'name', type: 'string', defaultSort: 'asc' },
    {
      title: 'Marketing URL',
      field: 'marketingUrl',
      type: 'string',
      render: (rd) => (
        <a href={rd.marketingUrl} target='_blank' rel='noopener noreferrer'>
          {rd.marketingUrl}
        </a>
      ),
    },
    {
      title: 'Icon',
      field: 'icon',
      type: 'string',
      render: (rd) => (rd?.icon ? <SmartIcon name={rd.icon} /> : null),
    },
    {
      title: 'Release Stage',
      field: 'releaseStage',
      type: 'string',
    },
  ];

  const addonColumns = [
    { title: 'Addon Id', field: 'addonId', type: 'string' },
    { title: 'Name', field: 'name', type: 'string' },
    { title: 'Description', field: 'description', type: 'string' },
  ];

  const [open, setOpen] = React.useState(false);

  const closeDialog = () => {
    setOpen(false);
    setisEditing(false);
    setEdit(null);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={1} direction='row'>
        <Grid item xs={12}>
          <h2>{type}</h2>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Paper>
              {type === 'permissions' && (
                <SmartGrid
                  columns={permissionColumns}
                  data={getGridData()}
                  title={''}
                  icons={tableIcons}
                  options={{
                    grouping: true,
                    filtering: true,
                  }}
                  sorting={true}
                  search={true}
                  actions={[
                    (rowData) => ({
                      icon: EditIcon,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => onEditClick(event, rowData),
                    }),
                    (rowData) => ({
                      icon: DeleteIcon,
                      tooltip: 'Delete',
                      onClick: (event, rowData) =>
                        onDeleteClick(event, rowData),
                    }),
                  ]}
                />
              )}

              {type === 'features' && (
                <SmartGrid
                  columns={featureColumns}
                  data={getGridData()}
                  title={''}
                  icons={tableIcons}
                  options={{
                    grouping: true,
                    filtering: true,
                  }}
                  sorting={true}
                  search={true}
                  actions={[
                    (rowData) => ({
                      icon: EditIcon,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => onEditClick(event, rowData),
                    }),
                    (rowData) => ({
                      icon: DeleteIcon,
                      tooltip: 'Delete',
                      onClick: (event, rowData) =>
                        onDeleteClick(event, rowData),
                    }),
                  ]}
                />
              )}

              {type === 'subscriptions' && (
                <SmartGrid
                  columns={subscriptionColumns}
                  data={getGridData()}
                  detailPanel={[
                    {
                      tooltip: 'Show Entry Points',
                      render: (rowData) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              padding: '0 10px',
                            }}
                          >
                            {rowData.entryPoints.map((ep, i) => {
                              return (
                                <EntryPoint
                                  cardStyles={{
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                  }}
                                  key={i}
                                  index={i}
                                  name={ep.name}
                                  objectTypes={ep.objectTypes}
                                  objectTypeOptions={ep.objectTypes}
                                  onChange={() => {}}
                                  readOnly
                                  showDividers={false}
                                  viewType={ep.viewType}
                                />
                              );
                            })}
                          </div>
                        );
                      },
                    },
                  ]}
                  title={''}
                  icons={tableIcons}
                  options={{
                    grouping: true,
                    filtering: true,
                  }}
                  sorting={true}
                  search={true}
                  actions={[
                    (rowData) => ({
                      icon: EditIcon,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => onEditClick(event, rowData),
                    }),
                    // (rowData) => ({
                    //   icon: DeleteIcon,
                    //   tooltip: 'Delete',
                    //   onClick: (event, rowData) =>
                    //     onDeleteClick(event, rowData),
                    // }),
                  ]}
                />
              )}

              {type === 'addons' && (
                <SmartGrid
                  columns={addonColumns}
                  data={getGridData()}
                  title={''}
                  icons={tableIcons}
                  options={{
                    grouping: true,
                    filtering: true,
                  }}
                  sorting={true}
                  search={true}
                  actions={[
                    (rowData) => ({
                      icon: EditIcon,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => onEditClick(event, rowData),
                    }),
                    (rowData) => ({
                      icon: DeleteIcon,
                      tooltip: 'Delete',
                      onClick: (event, rowData) =>
                        onDeleteClick(event, rowData),
                    }),
                  ]}
                />
              )}
            </Paper>
          )}
        </Grid>
      </Grid>

      <br></br>
      <Button variant='contained' color='primary' onClick={openDialog}>
        <b>Add NEW</b>
      </Button>

      {open && (
        <EntitiesModal
          type={type}
          closeDialog={closeDialog}
          handleSave={handleSave}
          edit={edit}
          entities={data.getAccessControlEntities}
        />
      )}
    </div>
  );
}

export default Entities;
