import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';

import Welcome from './Welcome';
import Mappings from './Mapping';
import Entities from './Entities';

import { Container, Image, Menu } from 'semantic-ui-react';

const Home = () => {
  const { authState, authService } = useOktaAuth();
  const [, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes

  const login = async () => authService.login('/');
  const logout = async () => authService.logout('/');

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        {authState.isAuthenticated && (
          <div>
            <Menu fixed='top' inverted>
              <Container>
                <Menu.Item as='a' header href='/'>
                  <Image size='mini' src='/react.svg' />
                  &nbsp; OS Access Control
                </Menu.Item>
                {authState.isAuthenticated && (
                  <Menu.Item>
                    <Link to='/features'>Features</Link>
                  </Menu.Item>
                )}
                {authState.isAuthenticated && (
                  <Menu.Item>
                    <Link to='/permissions'>Permissions</Link>
                  </Menu.Item>
                )}
                {authState.isAuthenticated && (
                  <Menu.Item>
                    <Link to='/subscriptions'>Subscriptions</Link>
                  </Menu.Item>
                )}
                {authState.isAuthenticated && (
                  <Menu.Item>
                    <Link to='/addons'>Add-ons</Link>
                  </Menu.Item>
                )}
                {authState.isAuthenticated && (
                  <Menu.Item>
                    <Link to='/mappings'>Mappings</Link>
                  </Menu.Item>
                )}
                {
                  <Menu.Item id='logout-button' as='a' onClick={logout}>
                    Logout
                  </Menu.Item>
                }
              </Container>
            </Menu>
            <Router>
              <div>
                <Switch>
                  <Route path='/:id' children={<Child />} />
                  <Route path='/' component={Welcome} />
                </Switch>
              </div>
            </Router>
          </div>
        )}

        {!authState.isAuthenticated && (
          <div>
            <Menu fixed='top' inverted>
              <Container>
                <Menu.Item as='a' header href='/'>
                  <Image size='mini' src='/react.svg' />
                  &nbsp; OS Access Control
                </Menu.Item>
                {
                  <Menu.Item as='a' style={{ float: 'right' }} onClick={login}>
                    Login
                  </Menu.Item>
                }
              </Container>
            </Menu>
            <div>
              You have been successfuly logged out of OS Access Control.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Home;

function Child() {
  let { id } = useParams();

  if (id === 'welcome') return <Welcome />;

  return id === 'mappings' ? <Mappings /> : <Entities type={id} />;
}
