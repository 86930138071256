import { useQuery } from '@apollo/react-hooks';
import Icon from '@mdi/react';
import { gql } from 'apollo-boost';
import PropTypes from 'prop-types';
import React from 'react';

const GET_ICON = gql`
  query iconPathByName($name: String!) {
    iconPathByName(name: $name) {
      path
    }
  }
`;

function SmartIcon({
  className,
  color,
  description,
  horizontal,
  name,
  rotate,
  size,
  spin,
  title,
  vertical,
}) {
  const { data } = useQuery(GET_ICON, {
    variables: { name },
    fetchPolicy: 'no-cache',
  });

  return data?.iconPathByName ? (
    <Icon
      className={className}
      color={color}
      description={description}
      horizontal={horizontal}
      path={data.iconPathByName.path}
      rotate={rotate}
      spin={spin}
      size={size}
      title={title}
      vertical={vertical}
    />
  ) : null;
}

SmartIcon.defaultProps = {
  size: 1,
};

SmartIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  horizontal: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rotate: PropTypes.number,
  size: PropTypes.number,
  spin: PropTypes.bool,
  title: PropTypes.string,
  vertical: PropTypes.bool,
};

export default SmartIcon;
