import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import EntryPoint from './EntryPoint';
import useObjectTypes from '../hooks/useObjectTypes';
import { defaultEntryPoint } from '../constants/entryPoint';

const EntryPoints = ({ entryPoints, onChange }) => {
  const objectTypeOptions = useObjectTypes();
  const [expanded, setExpanded] = useState(false);
  const [epState, setEps] = useState(entryPoints);
  const [needUpdate, setNeedUpdate] = useState(false);

  const handleEntryPoint = (idx, ep) => {
    // Delete
    if (!ep) {
      setEps(entryPoints.filter((_, i) => i !== idx));
    } else {
      // Update
      setEps(
        entryPoints.map((cur, i) => {
          if (i !== idx) return cur;
          return ep;
        })
      );
    }

    setNeedUpdate(true);
  };

  useEffect(() => {
    if (needUpdate) {
      onChange({ value: epState, fieldName: 'entryPoints' });
      setNeedUpdate(false);
    }
  }, [needUpdate, onChange, epState]);

  const addSubscriptionEntryPoint = () => {
    setEps(epState.concat(defaultEntryPoint));
    setNeedUpdate(true);
    if (!expanded) setExpanded(true);
  };

  return (
    <>
      <ExpansionPanel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{ marginTop: '10px', maxHeight: '250px', overflow: 'auto' }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='related-companies-content'
          id='related-companies-header'
        >
          <Typography variant='body1'>Entry Points</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            paddingRight: '5px',
            flexDirection: 'column',
          }}
        >
          <Divider />
          {epState.map((ep, i) => {
            return (
              <EntryPoint
                key={ep.name + i.toString()}
                index={i}
                name={ep.name}
                onChange={handleEntryPoint}
                objectTypes={ep.objectTypes}
                objectTypeOptions={objectTypeOptions}
                viewType={ep.viewType}
              />
            );
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Button
        color='primary'
        onClick={addSubscriptionEntryPoint}
        style={{ marginTop: '5px' }}
      >
        Add Entry Point
      </Button>
    </>
  );
};

EntryPoints.propTypes = {
  entryPoints: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      viewType: PropTypes.string,
      objectTypes: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  onChange: PropTypes.func.isRequired,
};

EntryPoints.defaultProps = {
  entryPoints: [],
};

export default EntryPoints;
