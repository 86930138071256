import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';

import viewTypes from '../constants/viewTypes';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const useStyles = makeStyles(styles);

const EntryPoint = ({
  cardStyles,
  index, // Maybe these should get ids?
  name,
  objectTypes,
  objectTypeOptions,
  onChange,
  readOnly,
  showDividers,
  viewType,
}) => {
  const classes = useStyles();
  const [nameState, setName] = useState(name);
  const [view, setView] = useState(viewType);
  const [objectTs, setObjectTs] = useState(objectTypes);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    // Skip the first render because they will be the same.
    if (needUpdate) {
      const entryPoint = {
        name: nameState,
        viewType: view,
        objectTypes: objectTs,
      };

      onChange(index, entryPoint);
    } else setNeedUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameState, view, objectTs]);

  const handleDelete = () => {
    setConfirmDelete(false);
    onChange(index);
  };

  return (
    <Card
      variant='outlined'
      style={{
        margin: '10px 0',
        paddingLeft: '2px',
        paddingRight: '2px',
        display: 'flex',
        flexDirection: 'column',
        ...cardStyles,
      }}
    >
      <TextField
        autoFocus
        disabled={readOnly}
        fullWidth
        label='Entry Point Name'
        margin='dense'
        onChange={(e) => setName(e.target.value)}
        value={nameState}
      />

      <InputLabel style={{ marginTop: '5px' }} shrink>
        View Type
      </InputLabel>
      <Select
        disabled={readOnly}
        fullWidth
        label='View Type'
        margin='dense'
        onChange={(e) => setView(e.target.value)}
        value={view}
      >
        {Object.values(viewTypes).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>

      <InputLabel style={{ marginTop: '5px' }} shrink>
        Object Types
      </InputLabel>
      <Select
        disabled={readOnly}
        fullWidth
        label='Object Types'
        margin='dense'
        multiple
        onChange={(e) => setObjectTs(e.target.value || [])}
        value={objectTs}
      >
        {objectTypeOptions.map((type) => (
          <MenuItem key={type} value={type}>
            {type.split(/(?=[A-Z])/).join(' ')}
          </MenuItem>
        ))}
      </Select>

      {!readOnly && (
        <IconButton
          color='secondary'
          size='medium'
          style={{ alignSelf: 'flex-end', marginTop: '2px' }}
          onClick={() => setConfirmDelete(true)}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {showDividers && <Divider light />}

      {confirmDelete && (
        <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
          <div className={classes.paper}>
            <p>Are you sure you wish to delete this entry point?</p>

            <Button onClick={handleDelete}>Confirm</Button>
            <Button color='secondary' onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </Card>
  );
};

EntryPoint.propTypes = {
  cardStyles: PropTypes.object,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  objectTypes: PropTypes.arrayOf(PropTypes.string),
  objectTypeOptions: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  showDividers: PropTypes.bool,
  viewType: PropTypes.string,
};

EntryPoint.defaultProps = {
  cardStyles: {},
  name: '',
  objectTypes: [],
  objectTypeOptions: [],
  readOnly: false,
  showDividers: true,
  viewType: '',
};

export default EntryPoint;
