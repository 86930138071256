import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FlexSearch from 'flexsearch';
import { FixedSizeGrid } from 'react-window';

import * as mdi from '@mdi/js';
import synonyms from './synonyms';
import debouncedInput from './debouncedInput';
import Icon from '@mdi/react';
const DebouncedTextField = debouncedInput(TextField, { timeout: 220 });

const searchIndex = FlexSearch.create({
  async: true,
  tokenize: 'full',
});

const allIconsMap = {};
const allIcons = Object.keys(mdi)
  .sort()
  .filter((key) => {
    if (key.match(/(Outline)$/)) return false;
    return true;
  })
  .map((key) => {
    let searchable = key.replace(/(Outline)$/, '');
    searchable = key.replace(/^(mdi)/, '');
    if (synonyms[searchable]) {
      searchable += ` ${synonyms[searchable]}`;
    }
    searchIndex.add(key, searchable);
    const icon = {
      key: key.replace(/^(mdi)/, ''),
      Icon: (props) => <Icon {...props} size={2} path={mdi[key]} />,
    };
    allIconsMap[key] = icon;
    return icon;
  });

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0 4px',
    fontSize: 12,
    '& p': {
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  iconSvg: {
    boxSizing: 'content-box',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.shortest,
    }),
    fontSize: 16,
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
  },
  iconGrid: {
    maxHeight: '400px',
    overflowY: 'scroll',
  },
}));

function IconPicker({ onChange, defaultValue }) {
  const classes = useStyles();
  const [keys, setKeys] = React.useState(null);
  const [value, setValue] = React.useState(defaultValue || '');

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = React.useMemo(
    () => (value) => {
      if (!isMounted.current) return;
      setValue(value);
      if (value === '') {
        setKeys(null);
      } else {
        searchIndex.search(value).then((results) => {
          setKeys(results);
        });
      }
    },
    []
  );

  const handleClickSelect = React.useCallback(
    (event) => {
      let title = event.currentTarget.getAttribute('name');
      handleChange(title);
      if (typeof onChange === 'function') {
        onChange({ selectedIcon: title });
      }
    },
    [onChange, handleChange]
  );

  const icons = React.useMemo(
    () => (keys === null ? allIcons : keys.map((key) => allIconsMap[key])),
    [keys]
  );

  return (
    <div>
      <DebouncedTextField
        fullWidth
        label='Icon'
        margin='dense'
        value={value}
        onBlur={(event) => {
          if (event.target.value === '') {
            onChange({ selectedIcon: null });
          }
        }}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        placeholer='Search Icons...'
      />
      <p>{`${icons.length} matching results`}</p>
      <IconGrid
        classes={classes}
        icons={icons}
        handleClickSelect={handleClickSelect}
      />
    </div>
  );
}

const columns = 4;
const width = 510;
const rowHeight = 110;

function IconGrid({ classes, icons, handleClickSelect }) {
  return (
    <FixedSizeGrid
      columnCount={columns}
      // -8 for padding
      columnWidth={width / columns - 8}
      height={400}
      rowCount={icons.length / columns + (icons.length % columns ? 1 : 0)}
      rowHeight={rowHeight}
      width={width}
    >
      {({ columnIndex, rowIndex, style }) => {
        let index = rowIndex * columns + columnIndex;
        const icon = icons[index];
        if (!icon) return <div style={style}></div>;
        return (
          <div style={{ ...style }} className={classes.icon}>
            <icon.Icon
              className={classes.iconSvg}
              tabIndex={-1}
              onClick={handleClickSelect}
              title={icon.key}
              name={icon.key}
            />
            <p className={classes.iconName} onClick={handleClickSelect}>
              {icon.key}
            </p>
          </div>
        );
      }}
    </FixedSizeGrid>
  );
}

export default IconPicker;
