import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function MappingDialog(props) {
  const { entities, edit, closeDialog } = props;

  const emptyMap = {
    appid: null,
    subscriptionId: null,
    featureId: null,
    allow3rdParty: true,
    permissions: [],
  };

  const [mapping, setMapping] = useState(edit || emptyMap);

  function handlePermission(event, value) {
    setMapping({
      ...mapping,
      permissions: value,
    });
  }

  function handleFeature(event, value) {
    setMapping({
      ...mapping,
      featureId: value,
    });
  }

  function handleSubscription(event, value) {
    setMapping({
      ...mapping,
      subscriptionId: value,
    });
  }

  function handle3rdParty() {
    setMapping({
      ...mapping,
      allow3rdParty: !mapping.allow3rdParty,
    });
  }

  function handleSave() {
    props.handleSave(mapping);
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={closeDialog}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {edit ? 'Edit ' : 'Add a new '} mapping
        </DialogTitle>
        {entities && entities.getAccessControlEntities ? (
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  value={mapping.subscriptionId}
                  onChange={(event, value) => handleSubscription(event, value)}
                  options={entities.getAccessControlEntities.subscriptions}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return option.subscriptionId === value.subscriptionId;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Subscription'
                      placeholder='Subscription'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={mapping.featureId}
                  onChange={(event, value) => handleFeature(event, value)}
                  options={entities.getAccessControlEntities.features}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return option.featureId === value.featureId;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Feature'
                      placeholder='Feature'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={mapping.permissions}
                  onChange={(event, value) => handlePermission(event, value)}
                  options={entities.getAccessControlEntities.permissions}
                  getOptionSelected={(option, value) => {
                    return option.permissionId === value.permissionId;
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Permissions'
                      placeholder='Permissions'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={mapping.allow3rdParty}
                      onChange={handle3rdParty}
                      color='primary'
                    />
                  }
                  label='Allow 3rd Party'
                />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <CircularProgress />
        )}
        <DialogActions>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSave} color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MappingDialog;
